import React, { Component } from 'react'

export default class About extends Component {
  render() {
    return (
      <div>
        <section className="colorlib-about" data-section="about">
        <div className="colorlib-narrow-content">
            <div className="row">
            <div className="col-md-12">
                <div className="row row-bottom-padded-sm animate-box" data-animate-effect="fadeInLeft">
                <div className="col-md-12">
                    <div className="about-desc">
                    <span className="heading-meta">About Me</span>
                    <h2 className="colorlib-heading">Who Am I?</h2>
                    <p>Hi, I'm Nathan Samson, a computer science student passionate about technology and innovation. Currently pursuing my Bachelor's degree at the University of Maryland Baltimore County, I am keen on exploring new technologies and really infatuated by the progress humans have made in recent years.</p>
                      <p>My academic journey is filled with relevant coursework such as Computer Science, Calculus, and Physics, laying a strong foundation for my technical skills. My interest in problem-solving and analytical reasoning has led me to dive deep into Data Structures & Algorithms. Beyond academics, I've been actively involved in IoT research and development, contributing to projects that harness the power of IoT for real-time data analysis and decision-making.</p>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
        </section>
        <section className="colorlib-about">
        <div className="colorlib-narrow-content">
            <div className="row">
            <div className="col-md-6 col-md-offset-3 col-md-pull-3 animate-box" data-animate-effect="fadeInLeft">
                <span className="heading-meta">What I do?</span>
                <h2 className="colorlib-heading">Here are some of my expertises</h2>
            </div>
            </div>
            <div className="row row-pt-md">
            <div className="col-md-4 text-center animate-box">
                <div className="services color-1">
                <span className="icon">
                    <i className="icon-bulb" />
                </span>
                <div className="desc">
                    <h3>AI/ML </h3>
                    <p>I have experience building AI from my long project for Connect4, employing advanced Reinforcement Learning techniques including Distributional Dueling Networks, Noisy Nets, and Prioritized Experience Replay, all built with PyTorch </p>
                </div>
                </div>
            </div>
            <div className="col-md-4 text-center animate-box">
                <div className="services color-3">
                <span className="icon">
                    <i className="icon-phone3" />
                </span>
                <div className="desc">
                <h3>Software Development</h3>
                <p>Strong proficiencies in Python, Java, and web technologies through Expierence </p>
                </div>
                </div>
            </div>
            <div className="col-md-4 text-center animate-box">
                <div className="services color-5">
                <span className="icon">
                    <i className="icon-data" />
                </span>
                <div className="desc">
                <h3>Data Science/Analysis & IoT</h3>
                    <p>AIn my role at UMBC's DAMS lab, I focus on harnessing data through sophisticated algorithms, facilitating insightful data analysis for IoT applications.</p>
                </div>
                </div>
            </div>
            <div className="col-md-4 text-center animate-box">
                <div className="services color-2">
                <span className="icon">
                    <i className="icon-data" />
                </span>
                <div className="desc">
                    <h3>Internet of Things </h3>
                    <p>My work extends to the practical applications of IoT, leveraging tools like Jenkins, Kubernetes, and Docker to streamline and enhance device connectivity and communication. </p>
                </div>
                </div>
            </div>
            {/*
            <div className="col-md-4 text-center animate-box">
                <div className="services color-2">
                <span className="icon">
                    <i className="icon-data" />
                </span>
                <div className="desc">
                    <h3>Dev Ops</h3>
                    <p>Jenkins , Kubernetes , Docker </p>
                </div>
                </div>
            </div>
            <div className="col-md-4 text-center animate-box">
                <div className="services color-4">
                <span className="icon">
                    <i className="icon-layers2" />
                </span>
                <div className="desc">
                    <h3>Graphic Design</h3>
                    <p>My friend knows .. P</p>
                </div>
                </div>
            </div>
            <div className="col-md-4 text-center animate-box">
                <div className="services color-6">
                <span className="icon">
                    <i className="icon-phone3" />
                </span>
                <div className="desc">
                    <h3>Digital Marketing</h3>
                    <p>I use Instagram eight hours a day :) </p>
                </div>
                </div>
            </div>
            */}
            </div>
        </div>
        </section>
      </div>
    )
  }
}
